/**
 * @prettier
 * @flow
 */

import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import Button from 'liana-ui/legacy/components/button/Button';
import Icon from 'liana-ui/legacy/components/icon/Icon';

import 'jquery-ui/ui/effect';

type Props = {
	intl: Intl,
	index: number, // Unique, incremental ID
	children: React.Node,
	classes: string,
	isNew: boolean,
	isMobile: boolean,
	isDisabled: boolean,
	isDeletable: boolean,
	onAdd: () => mixed,
	onDelete: () => mixed
};

class ItemTableItem extends React.PureComponent<Props> {
	$item: JQuery;
	constructor(props: Props) {
		super(props);
	}
	static defaultProps = {
		children: [],
		classes: '',
		isNew: false,
		isMobile: false,
		isDeletable: true,
		isDisabled: false
	};
	componentDidMount() {
		if (this.props.isNew === true) {
			this._highlightRow(this.$item);
		}
	}

	// Retrieve component translations
	_translate(): { addRow: string, delRow: string } {
		return {
			createRow: this.props.intl.formatMessage({
				id: 'component.item-table.createRow'
			}),
			removeRow: this.props.intl.formatMessage({
				id: 'component.item-table.removeRow'
			})
		};
	}

	_highlightRow = ($row) => {
		$row.animate(
			{
				backgroundColor: 'yellow'
			},
			300,
			function () {
				$row.animate(
					{
						backgroundColor: 'transparent'
					},
					300
				);
			}
		);
	};

	render() {
		let translations = this._translate(),
			rowClasses = classNames('item-table-item', this.props.classes, {
				disabled: this.props.isDisabled === true
			});

		return (
			<tr
				ref={(ref) => (this.$item = ref ? $(ref) : null)}
				data-index={this.props.index}
				className={rowClasses}
				data-testid={this.props.testID || ItemTableItem.name}
			>
				<td className='content remove-paddings'>{this.props.children}</td>
				<td className='collapsing right aligned nowrap'>
					<Button
						classes='small circular icon'
						tooltip={{
							'data-content': translations.createRow,
							'data-variation': 'tiny',
							'data-delay': 500
						}}
						onClick={this.props.onAdd.bind(this, this.props.index)}
					>
						<Icon classes='plus' />
					</Button>
					<Button
						classes={`small circular icon remove-margins ${
							this.props.isDeletable !== true ? 'disabled' : ''
						}`}
						tooltip={{
							'data-content': translations.removeRow,
							'data-variation': 'tiny',
							'data-delay': 500
						}}
						onClick={this.props.onDelete.bind(this, this.props.index)}
					>
						<Icon classes='remove' />
					</Button>
				</td>
			</tr>
		);
	}
}

let Wrapper = injectIntl(ItemTableItem, { forwardRef: true });
Wrapper.displayName = 'ItemTableItem';
export default Wrapper;
