/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import ItemTableItem from 'liana-ui/legacy/components/item-table/ItemTableItem';

// prettier-ignore
type Props = {
	/** Item table rows as children. */
	children: React.Node,
	/** A item table can have additional classes. */
	classes?: string,
	/** Function that creates a new empty row */
	factory: () => React.Element<*>,
	/** Function called when adding a row. */
	onAdd: (
		ctr: number
	) => mixed,
	/** Function vcalled when deleting a row. */
	onDelete: (
		idx: number | string
	) => mixed
};

type Item = {
	index: number,
	ref: ?React.Node,
	item: ?React.Node
};

/** BASED ON: https://fomantic-ui.com/collections/table.html */
export default class ItemTable extends React.PureComponent<Props> {
	_table: { current: React.ElementRef<'table'> | null };
	_items: Array<Item>;
	_counter: number;
	_new: number;

	constructor(props: Props) {
		super(props);
		this._table = React.createRef();
		this._counter = -1; // Index counter
		this._new = -1; // Determines which row was added
		this._items = props.children ? React.Children.map(props.children, (item, idx) => this._itemize(item, idx)) : []; // ItemTableItem element descriptors
	}

	static defaultProps = {
		classes: ''
	};

	componentDidMount = () => {
		this._counter = this._items.length; // No "added" animations until after mounting
	};

	getContent = (): Array<Item> => this._items;

	_setRef = (idx: number, ref: React.Node): void => {
		let index = this._findIndex(idx);
		if (index > -1) {
			this._items[index].ref = Safely.ref(ref);
		}
	};
	_findIndex = (index: number): number => {
		for (let i = 0; i < this._items.length; i++) {
			if (this._items[i].index === index) {
				return i;
			}
		}
		return -1;
	};
	_itemize = (element: React.Element<*>, index: number): Item => {
		return {
			index: index,
			ref: null,
			item: React.cloneElement(element, {
				key: index,
				ref: this._setRef.bind(this, index)
			})
		};
	};
	_addItem = (index: number): void => {
		let idx = this._findIndex(index),
			item = this._itemize(this.props.factory(), this._counter++);
		if (idx < 0) {
			this._items.push(item);
		} else {
			this._items.splice(idx + 1, 0, item);
		}
		this._new = this._counter; // Row highlight
		if (typeof this.props.onAdd === 'function') {
			this.props.onAdd(this._counter);
		}
		this._triggerFormChange();
		this.forceUpdate(); // Necessitated by: Use of jQuery/SUI and variable children
	};
	_delItem = (index: number): void => {
		let idx = this._findIndex(index);
		if (idx > -1) {
			let $row = $(this._table.current).find(`tr[data-index="${index}"]`);
			$row.addClass('removed').transition({
				animation: 'fly left',
				onComplete: () => {
					this._items.splice(idx, 1);
					if (typeof this.props.onDelete === 'function') {
						this.props.onDelete(index);
					}
					this._triggerFormChange();
					this.forceUpdate(); // Necessitated by: Use of jQuery/SUI and variable children
				}
			});
		}
	};

	_triggerFormChange = () => {
		// Make form onChange trigger
		let $table = $(this._table.current);
		if ($table) {
			$table.find('input, select, textarea').trigger('change');
		}
	};
	render() {
		let tableClasses = classNames('ui basic table item-table fluid', this.props.classes);
		return (
			<table ref={this._table} className={tableClasses} data-testid={this.props.testID || ItemTable.name}>
				<tbody>
					{this._items.map((item, idx) => {
						return (
							<ItemTableItem
								key={item.index}
								index={item.index}
								isNew={this._new === item.index + 1}
								isDeletable={this._items.length > 1}
								onAdd={this._addItem}
								onDelete={this._delItem}
							>
								{item.item}
							</ItemTableItem>
						);
					})}
				</tbody>
			</table>
		);
	}
}
